import React, { useState } from "react";
import styled from "styled-components";

const StyledContactForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: -10px;
  width: 60%;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 80%;
  }
  @media (max-width: 375px) {
    top: 30px;
  }
  @media (max-width: 320px) {
    top: 70px;
  }
`;

const Sender = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1276px) {
    flex-direction: column;
  }
`;

const Name = styled.input`
  background: #2e3440;
  border: 1px solid #4c566a;
  box-sizing: border-box;
  border-radius: 4px;
  height: 45px;
  min-width: 49%;
  color: #fff;
  font-family: "Ubuntu", sans-serif;
  padding: 1em;
  margin-bottom: 1em;
`;

const Email = styled.input`
  background: #2e3440;
  border: 1px solid #4c566a;
  box-sizing: border-box;
  border-radius: 4px;
  min-width: 49%;
  height: 45px;
  color: #fff;
  font-family: "Ubuntu", sans-serif;
  padding: 1em;
  @media (max-width: 1276px) {
    margin-bottom: 1em;
  }
`;

const Message = styled.textarea`
  background: #2e3440;
  border: 1px solid #4c566a;
  box-sizing: border-box;
  border-radius: 4px;
  height: 172px;
  color: #fff;
  width: 100%;
  font-family: "Ubuntu", sans-serif;
  padding: 1em;
`;

const Submit = styled.button`
  background: linear-gradient(180deg, #ff0075 0%, #ff8a00 100%);
  border-radius: 4px;
  border: none;
  text-align: center;
  color: #fff;
  font-family: "Ubuntu", sans-serif;
  font-weight: 600;
  font-size: 1.3em;
  text-decoration: none;
  padding: 0.5em 1.5em;
  align-self: center;
  margin-top: 1em;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  @media (max-width: 768px) {
  }
`;

function MyForm() {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  });
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      form.reset();
    }
  };
  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    fetch("https://formspree.io/xnqdeyoj", {
      method: "POST",
      mode: "no-cors",
      body: new FormData(form)
    })
      .then(r => {
        handleServerResponse(true, "Thanks", form);
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };
  return (
    <StyledContactForm onSubmit={handleOnSubmit} autoComplete="off">
      <h1 style={{ textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>
        Say Hello
        <span
          style={{
            color: "#FF8A00"
          }}
        >
          .
        </span>
      </h1>
      <Sender>
        <Name
          id="name"
          type="text"
          name="name"
          placeholder="Name... (optional)"
        />
        <Email
          id="email"
          type="email"
          name="email"
          placeholder="*Email..."
          required
        />
      </Sender>
      <Message id="message" name="message" placeholder="*Message..." required />
      <Submit type="submit" disabled={serverState.submitting}>
        Submit
      </Submit>
      {serverState.status && (
        <h1
          style={{ textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
          className={!serverState.status.ok ? "errorMsg" : ""}
        >
          {serverState.status.msg}
          <span
            style={{
              color: "#FF8A00"
            }}
          >
            !
          </span>
        </h1>
      )}
    </StyledContactForm>
  );
}

export default MyForm;
