import React from "react";
import ContentWrapper from "./components/ContentWrapper";
import Header from "./components/Header";
import MainContent from "./components/MainContent";
import ContactForm from "./components/ContactForm";
import Footer from "./components/Footer";
import GlobalStyle from "./styles/global";

export default () => (
  <ContentWrapper>
    <Header></Header>
    <MainContent></MainContent>
    <ContactForm></ContactForm>
    <Footer></Footer>
    <GlobalStyle />
  </ContentWrapper>
);
