import React from "react";
import { PinkTriangle, OrangeTriangle, GrayTriangle } from "../styles/header";
import styled from "styled-components";
import Typist from "react-typist";

const HeaderText = styled.div`
  position: absolute;
  top: 4em;
  zindex: 1;
  left: 33%;
  font-size: 2.5em;
  font-weight: 500;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @media (max-width: 768px) {
    top: 2.7em;
    left: 30%;
  }
  @media (max-width: 414px) {
    top: 2.7em;
    left: 25%;
  }
`;

const TextSizes = styled.div`
  font-size: 1em;
  @media (max-width: 768px) {
    font-size: 0.8em;
  }
  @media (max-width: 320px) {
    font-size: 0.7em;
  }
`;

const Header = () => {
  return (
    <div
      className="header"
      style={{
        display: "flex",
        justifyContent: "space-between",
        position: "relative",
      }}
    >
      <PinkTriangle></PinkTriangle>
      <OrangeTriangle></OrangeTriangle>
      <GrayTriangle></GrayTriangle>
      <HeaderText>
        <TextSizes>
          <div style={{ position: "relative", bottom: "-20px" }}>
            Hi<span style={{ color: "#FF8A00" }}>.</span>
          </div>
          <div>
            I<span style={{ color: "#FF0075" }}>'</span>m Marius
            <img
              src="/mb_logo.svg?v=1"
              width="55px;"
              style={{ position: "relative", top: "10px", left: "3px" }}
              alt="."
            ></img>
          </div>
          <div
            style={{ fontSize: "0.5em", fontWeight: 100, marginTop: "0.5em" }}
          >
            <Typist>
              A Software Engineer
              <span style={{ color: "#FF8A00" }}>.</span>
              <Typist.Backspace count={18} delay={500} />
              Consultant
              <span style={{ color: "#FF8A00" }}>.</span>
              <Typist.Backspace count={11} delay={500} />
              full-stack developer
              <span style={{ color: "#FF8A00" }}>.</span>
            </Typist>
          </div>
        </TextSizes>
      </HeaderText>
    </div>
  );
};

export default Header;
