import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CardSlider = () => {
  var settings = {
    centerMode: true,
    centerPadding: "110px",
    slidesToShow: 3,
    arrows: false,
    dots: true,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          centerMode: true,
          centerPadding: "70px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1336,
        settings: {
          centerMode: true,
          centerPadding: "0px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          centerMode: true,
          centerPadding: "150px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 850,
        settings: {
          centerMode: true,
          centerPadding: "100px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerMode: true,
          centerPadding: "0",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerMode: true,
          centerPadding: "0px",
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="slider">
      <Slider {...settings}>
        <SliderCard className="card">
          <CardTitle>Hyped</CardTitle>
          <p>
            Hypedは、ゲームやマンガやライトノベルの最新発売をチェックできるソーシャルネットワークです。
          </p>
          <Link as="a" href="https://hyped.jp/" target="_blank">
            Visit
          </Link>
        </SliderCard>
        <SliderCard className="card">
          <CardTitle>Flowy</CardTitle>
          <p>
            じぶんらしい未来を描ける、お金の準備がカンタンにできる「お金のマイアプリ」です。
          </p>
          <Link
            as="a"
            href="https://apps.apple.com/jp/app/flowy-%E3%81%8A%E9%87%91%E3%81%A8%E6%9C%AA%E6%9D%A5%E3%82%92%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3%E3%81%97%E3%82%88%E3%81%86/id1635720334"
            target="_blank"
          >
            Visit
          </Link>
        </SliderCard>
        <SliderCard className="card">
          <CardTitle>Chisan</CardTitle>
          <p>総本山智積のホームページ。</p>
          <Link as="a" href="https://chisan.or.jp/" target="_blank">
            Visit
          </Link>
        </SliderCard>
      </Slider>
    </div>
  );
};

const StyledCardSlider = styled(CardSlider)``;

const FirstTriangle = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  height: 0;
  border-top: 80px solid transparent;
  border-right: 60vw solid #2e3440;
  border-bottom: 0px solid transparent;
  @media (max-width: 768px) {
    border-right: 100vw solid #2e3440;
  }
`;

const Rectangle = styled.div`
  position: absolute;
  top: 80px;
  width: 100%;
  height: 520px;
  background: #2e3440;
  @media (max-width: 768px) {
    height: 500px;
  }
  @media (max-width: 414px) {
    height: 540px;
  }
  @media (max-width: 375px) {
    height: 570px;
  }
  @media (max-width: 320px) {
    height: 610px;
  }
`;

const SecondTriangle = styled.div`
  position: absolute;
  right: 0;
  top: 600px;
  width: 0;
  height: 0;
  border-top: 80px solid transparent;
  border-right: 60vw solid #2e3440;
  border-bottom: 0px solid transparent;
  transform: rotate(180deg) scaleX(-1);
  @media (max-width: 768px) {
    top: 580px;
    border-right: 100vw solid #2e3440;
  }
  @media (max-width: 414px) {
    top: 620px;
  }
  @media (max-width: 375px) {
    top: 650px;
  }
  @media (max-width: 320px) {
    top: 690px;
  }
`;

const Description = styled.div`
  padding: 2em 10em;
  font-size: 1em;
  line-height: 1.4;
  font-weight: 400;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @media (max-width: 1529px) {
    padding: 2em 2.5em;
  }
`;

const Projects = styled.div`
  // display: flex;
  // padding: 3em 2em;
  // padding-left: 4em;
  // overflow-x: hidden;
`;

const SectionCard = styled.div`
  height: 50px;
  align-self: center;
  margin: 0;
  position: absolute;
  left: -75px;
  top: 270px;
  z-index: 3;
  background: rgb(255, 138, 0);
  background: linear-gradient(
    0deg,
    rgba(255, 138, 0, 1) 0%,
    rgba(255, 0, 117, 1) 100%
  );
  font-size: 1.5em;
  font-weight: bold;
  border-radius: 4px;
  display: flex;
  text-align: center;
  align-items: center;
  padding: 0 1em;
  margin-right: 1.5em;
  transform: rotate(-90deg);
  @media (max-width: 1225px) {
    top: 280px;
  }
  @media (max-width: 969px) {
    top: 300px;
  }
  @media (max-width: 855px) {
    top: 330px;
  }
  @media (max-width: 768px) {
    left: -47px;
    top: 270px;
  }
  @media (max-width: 480px) {
    left: -35px;
    top: 300px;
  }
  @media (max-width: 414px) {
    left: -47px;
    top: 320px;
  }
  @media (max-width: 320px) {
    top: 350px;
  }
`;

const SliderCard = styled.div`
  position: relative;
  background: #17141d;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 1.2em 1em;
  box-shadow: -1rem 0 2rem #000;
  transition: 0.15s;
  min-height: 280px;
  min-width: 171px;
  max-width: 210px;
  margin: 2em;
  z-index: 0;
  & > img,
  p {
    max-height: inherit;
    margin: 0.5em 0 0 0;
  }
  & > p {
    margin-top: 2em;
    font-size: 0.8em;
    line-height: 1.6;
  }
  @media (max-width: 480px) {
    margin-left: 9em;
    margin-top: 1.8em;
    box-shadow: -0.5rem 0 1.2rem #000;
  }
  @media (max-width: 468px) {
    margin-top: 1em;
    margin-left: 9em;
  }
  @media (max-width: 414px) {
    margin-left: 6em;
    box-shadow: -0.1rem 0 1rem #000;
    min-height: 300px;
    max-width: 240px;
    & > p {
      margin-top: 1em;
      font-size: 15px;
      line-height: 1.6;
    }
  }
  @media (max-width: 412px) {
    margin-left: 6em;
  }
  @media (max-width: 375px) {
    margin-left: 4.5em;
    box-shadow: -0.1rem 0 1rem #000;
  }
  @media (max-width: 320px) {
    max-width: 210px;
    margin-left: 3.7em;
  }
`;

const CardTitle = styled.div`
  font-weight: bold;
  font-size: ${(props) => props.fontSize || "1.5em"};
  margin-top: 1em;
  @media (max-width: 768px) {
  }
`;

const Link = styled.button`
  position: absolute;
  background: linear-gradient(180deg, #ff0075 0%, #ff8a00 100%);
  border-radius: 4px;
  text-align: center;
  color: #fff;
  font-weight: 400;
  font-size: 1em;
  text-decoration: none;
  padding: 0.2em 0.4em;
  max-width: 33px;
  bottom: 1em;
  right: 1em;
  @media (max-width: 414px) {
    padding: 0.4em 0.6em;
    font-weight: 500;
  }
`;

const MainContent = () => {
  return (
    <div
      className="mainContent"
      style={{
        position: "relative",
        top: "-50px",
        height: "610px",
      }}
    >
      <div
        className="merged-triangles"
        style={{
          position: "relative",
          height: "inherit",
        }}
      >
        <FirstTriangle></FirstTriangle>
        <Rectangle>
          <Description>
            Software Engineer. Strong expertise in Web Development, Database
            Design, Web Design, SEO, Cloud Platforms. Bachelor's Degree in
            Computer Science and Master's Degree in Information Technology.
          </Description>
          <Projects>
            <SectionCard>Projects</SectionCard>
            <StyledCardSlider></StyledCardSlider>
          </Projects>
        </Rectangle>
        <SecondTriangle></SecondTriangle>
      </div>
    </div>
  );
};

export default MainContent;
