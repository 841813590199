import React from "react";
import styled from "styled-components";

const FooterWrapper = styled.div`
  margin-top: 3em;
  display: flex;
  justify-content: space-between;
  position: relative;
  @media (max-width: 320px) {
    margin-top: 7em;
  }
`;

const LeftTriangle = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8vw 0 0px 300px;
  border-color: transparent transparent transparent #2e3440;
  z-index: 0;
  @media (max-width: 1024px) {
    border-width: 8vw 0 120px 230px;
  }
  @media (max-width: 768px) {
    border-width: 10vw 0 70px 200px;
  }
`;

const LeftGrayTriangle = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  top: -30px;
  border-style: solid;
  border-width: 8vw 0 0px 200px;
  border-color: transparent transparent transparent #434c5e;
  z-index: 0;
  @media (max-width: 768px) {
    border-width: 9vw 0 90px 200px;
  }
  @media (max-width: 1024px) {
    border-width: 20vw 0 0px 210px;
  }
`;

const RightTriangle = styled.div`
  // position: absolute;
  border-top: 75px solid transparent;
  border-right: 59vw solid #2e3440;
  border-bottom: 0px solid transparent;
  z-index: 1;
  @media (max-width: 768px) {
    border-right: 100vw solid #2e3440;
  }
`;

const Rectangle = styled.div`
  width: 100%;
  height: 200px;
  background: #2e3440;
  @media (max-width: 768px) {
    height: 150px;
  }
`;

const FooterText = styled.div`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 1em;
  text-align: center;
  z-index: 1;
  padding: 0 2em;
  font-weight: 100;
  font-size: 0.9em;
  @media (max-width: 768px) {
    bottom: 0.5em;
  }
  @media (max-width: 320px) {
    font-size: 0.7em;
  }
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <LeftGrayTriangle></LeftGrayTriangle>
      <LeftTriangle></LeftTriangle>
      <RightTriangle></RightTriangle>
      <Rectangle></Rectangle>
      <FooterText>
        <div className="socials">
          <a
            href="https://www.linkedin.com/in/marius-beineris-6092861a1/"
            target="_blank"
            rel="noopener noreferrer"
            className="fa fa-linkedin"
          ></a>
          <a
            href="https://github.com/mbeineris"
            target="_blank"
            rel="noopener noreferrer"
            className="fa fa-github"
          ></a>
        </div>
        Copyright © 2024 Marius Beineris • Software Engineer & Consultant
      </FooterText>
    </FooterWrapper>
  );
};

export default Footer;
