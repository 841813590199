import React from "react";
import styled from "styled-components";

const StyledContentWrapper = styled.div`
  width: 60%;
  background-color: #3b4252;
  box-shadow: 8px 8px 15px rgba(0, 0, 0, 0.25);
  height: 100%;
  margin: 2em auto;
  @media (max-width: 768px) {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    box-shadow: none;
  }
`;

const ContentWrapper = ({ children }) => {
  return <StyledContentWrapper>{children}</StyledContentWrapper>;
};

export default ContentWrapper;
