import styled from "styled-components";

/* Desktop */
export const PinkTriangle = styled.div`
  width: 0;
  height: 0;
  border-top: 0px solid transparent;
  // #3D2FBC #ff0075
  border-left: 20vw solid #ff0075;
  border-bottom: 400px solid transparent;
  z-index: 1;
  @media (max-width: 768px) {
    border-bottom: 300px solid transparent;
    border-left: 30vw solid #ff0075;
  }
`;

export const OrangeTriangle = styled.div`
  width: 0;
  height: 0;
  border-top: 0px solid transparent;
  // #C60D94 #ff8a00
  border-right: 39vw solid #ff8a00;
  border-bottom: 280px solid transparent;
  flex-grow: 1;
  z-index: 1;
  @media (max-width: 768px) {
    border-bottom: 180px solid transparent;
    border-right: 70vw solid #ff8a00;
  }
`;

export const GrayTriangle = styled.div`
  position: absolute;
  right: 0;
  width: 0;
  height: 0;
  border-top: 0px solid transparent;
  border-right: 39vw solid #434c5e;
  border-bottom: 310px solid transparent;
  flex-grow: 1;
  z-index: 0;
  @media (max-width: 768px) {
    border-bottom: 210px solid transparent;
    border-right: 70vw solid #434c5e;
  }
`;
