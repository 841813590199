import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
* {
  scroll-behavior: smooth;
}

body {
  font-family: "Ubuntu", sans-serif;
  font-weight: 300;
  font-size: 16px;
  color: #e5e5e5;
  margin: 0;
  padding: 0;
  background-color: rgb(76, 86, 106);
}

.container {
  background-color: #4c566a;
  padding: 2em 0;
}
@media (max-width: 768px) {
  .container {
    padding: 0;
  }
}

*:focus {
  outline: none;
}

.slider {
  // margin: 0 7%;
}

@media (pointer: fine) {
  a span {
    display: none;
  }
  a:hover span {
    display: inline-block;
  }
}

.socials {
  margin-bottom: 1.5em;
}

/* Style all font awesome icons */
.fa {
  padding: 10px;
  font-size: 25px;
  width: 35px;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
}

/* Add a hover effect if you want */
.fa:hover {
  opacity: 0.7;
}

.fa-linkedin {
  background: #007bb5;
  color: white;
}

.fa-github {
  background: #444;
  color: white;
}

.socials {
  display: flex;
  width: 20%;
  margin: 1em auto;
  justify-content: space-around;
}

@media (max-width: 768px) {
  .socials {
    margin-bottom: 0.8em;
    width: 50%;
  }
  .fa {
    width: 28px;
    font-size: 18px;
  }
}


.Typist .Cursor {
  display: inline-block; }
  .Typist .Cursor--blinking {
    opacity: 1;
    animation: blink 1s linear infinite; }

@keyframes blink {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@font-face {
  font-family: "FontAwesome";
  font-weight: normal;
  font-style : normal;
          src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?v=4.3.0");
          src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?#iefix&v=4.3.0") format("embedded-opentype"),
                url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff2?v=4.3.0") format("woff2"),
                url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff?v=4.3.0") format("woff"),
                url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.ttf?v=4.3.0") format("truetype"),
                url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.svg?v=4.3.0#fontawesomeregular") format("svg");
  }

.fa-linkedin:before {
    font-family: FontAwesome;
    content: "\f0e1";
}

.fa-github:before {
  font-family: FontAwesome;
  content: "\f09b";
}

.slick-dots {
  bottom: -15px;
}
.slick-dots li button:before {
  font-size: 10px;
}
.slick-dots li {
  margin: 0;
}
`;

export default GlobalStyle;
